<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <a-button type="primary"  @click="$utils.linkTo('/shop/coupon/add')">新增</a-button>
      <a-button type="primary"  @click="configEdit" style="margin-left:20px;">优惠券设置</a-button>
    </Breadcrumb>

    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }"
            :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item label="名称:">
        <a-input v-model="form.name" placeholder="请输入内容"/>
      </a-form-item>
      <a-form-item label="渠道:">
         <a-select v-model="form.channelId" style="width: 100%" placeholder="请选择">
          <a-select-option v-for="(item, index) in channelList" :key="index" :value="item.code">{{ item.name }}</a-select-option>
          </a-select>
      </a-form-item>
      <a-form-item label="状态:">
         <a-select v-model="form.status" placeholder="请选择">
          <a-select-option :value="0">新建</a-select-option>
          <a-select-option :value="1">进行中</a-select-option>
          <a-select-option :value="2">已失效</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm('form')" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>
    <a-table :data-source="list"
             :rowKey="(record,index)=>{return index}"
             :columns="columns"
             :pagination="pagination"
             :scroll="{ x: 1400, y: 580 }"
             bordered>
      <div slot="action1" slot-scope="scope">
        <div style="color:#1890ff;">{{scope.name}}</div>
      </div>
      <div slot="action2" slot-scope="scope">
        <div>{{scope.residue >= 0 ? scope.residue : '无限'}}</div>
      </div>
      <span slot="action" slot-scope="scope">
        <a href="#"  v-if="scope.status != '1'" @click="edit(scope)" style="margin-left: 10px;text-align: center">编辑</a>
        <a-popconfirm
          title="确定要生效吗？"
          ok-text="是"
          cancel-text="否"
          @confirm="confirmStatus(scope.id, 1)"
          @cancel="cancel"
          v-if="scope.status === '0'">
            <a href="#" style="margin-left: 10px;text-align: center">生效</a>
        </a-popconfirm>
        <a-popconfirm
          title="确定要失效吗？"
          ok-text="是"
          cancel-text="否"
          @confirm="confirmStatus(scope.id, 2)"
          @cancel="cancel"
          v-if="scope.status === '1'">
            <a href="#" style="margin-left: 10px;text-align: center">失效</a>
        </a-popconfirm>
        <a-popconfirm
          title="确定要删除吗？"
          ok-text="是"
          cancel-text="否"
          @confirm="confirmDel(scope.id)"
          @cancel="cancel"
          v-if="scope.status === '0' || scope.status === '2'">
            <a href="#" style="margin-left: 10px;text-align: center">删除</a>
        </a-popconfirm>
        <a style="margin-left: 10px;text-align: center"
              @click="handleViewItem(scope)">查看优惠券</a>
      </span>
    </a-table>

     <!-- 编辑 评价审核设置 弹窗 -->
    <a-modal
      :title="configEditModalTitle"
      :footer="null"
      :visible="configEditVisible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
        <ConfigEdit  @ok="handleOk" @cancel="handleCancel" ref="configEditModalRef"></ConfigEdit>
    </a-modal>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import ConfigEdit from './dialog/configEdit.vue'
export default {
  components: {
    Breadcrumb,
    ConfigEdit
  },
  data () {
    return {
      breadList: [
        {
          name: '优惠券管理',
          url: ''
        }
      ],
      configEditModalTitle: '优惠券设置',
      configEditVisible: false, // 优惠券设置弹窗
      form: {
        name: '',
        status: '',
        channelId: ''
      },
      columns: [
        { title: '优惠券名称', width: 200, key: 'name', scopedSlots: { customRender: 'action1' } },
        { title: '备注', width: 150, key: 'memo', dataIndex: 'memo' },
        { title: '类型', width: 100, key: 'typeName', dataIndex: 'typeName' },
        { title: '适应渠道', width: 150, key: 'channelName', dataIndex: 'channelName' },
        { title: '优惠券说明', width: 150, key: 'description', dataIndex: 'description' },
        { title: '状态', width: 90, key: 'statusName', dataIndex: 'statusName' },
        { title: '已领取', width: 100, key: 'issue', dataIndex: 'issue' },
        { title: '剩余', width: 100, key: 'residue', scopedSlots: { customRender: 'action2' } },
        { title: '已使用', width: 100, key: 'used', dataIndex: 'used' },
        { title: '操作', key: 'operation', fixed: 'right', width: 260, scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      channelList: [],
      list: [],
      selectedRowKeys: [],
      isQueryLoading: false
    }
  },
  created () {
    this.getChannelList()
    this.reloadList()
  },
  mounted () {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.loadList()
      }
    })
  },
  methods: {
    // 编辑优惠券
    edit (app) {
      const { href } = this.$router.resolve({
        path: '/coupon/add',
        query: {
          id: app.id
        }
      })
      window.open(href, '_blank')
    },
    // 优惠券类型下拉列表
    async getChannelList () {
      const res = await this.$store.dispatch('http', {
        api: 'getChannelList',
        isJson: false,
        params: {
          pageNo: 1,
          pageSize: 5000
        }
      })
      if (res && res.records) {
        const that = this
        res.records.map(item => {
          that.channelList.push({ code: item.id, name: item.name })
        })
      }
    },
    handleViewItem (scope) {
      this.$router.push({
        name: 'ProCouponItemList',
        params: {
          couponId: scope.id,
          couponStatus: scope.status
        }
      })
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: '',
        status: '',
        channelId: ''
      }
      this.queryList()
    },
    // 是否确认删除
    confirmDel (id) {
      this.del(id)
    },
    // 是否确认更新状态
    confirmStatus (id, status) {
      this.updateStatus(id, status)
    },
    cancel (values) {
      console.log(values)
    },
    async del (id) {
      const res = await this.$store.dispatch('http', {
        api: 'removeCoupon',
        query: {
          id: id
        }
      })
      if (res) {
        this.$store.dispatch('showToast', {
          type: 'success',
          message: '删除成功'
        })
        this.queryList()
      }
    },
    async updateStatus (id, status) {
      const res = await this.$store.dispatch('http', {
        api: 'couponUpdateStatus',
        query: {
          couponId: id,
          status: status
        }
      })
      if (res) {
        this.$store.dispatch('showToast', {
          type: 'success',
          message: '操作成功'
        })
        this.loadList()
      }
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
        this.selectedRowKeys = []
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {
    }) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {
    }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'couponList',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.pageNo = pageNo
      this.reloadList()
    },
    // 评价审核设置
    configEdit () {
      this.configEditModalTitle = '优惠券设置'
      this.configEditVisible = true
      this.$nextTick(() => {
        this.$refs.configEditModalRef.init()
      })
    },
    handleOk (e) {
      setTimeout(() => {
        this.configEditVisible = false
      })
    },
    handleCancel (e) {
      this.configEditVisible = false
    }
  }
}
</script>
<style lang="scss" module>
.container {
  width: auto;
}
</style>
